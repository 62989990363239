/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web'
import Keycloak from 'keycloak-js'
import { QueryProvider } from 'src/features/query'
import { routes } from '../routes'
import { ThemeProvider } from '../theme/provider'
import { PageLayout } from '../components/layouts/PageLayout'
import { NotificationsProvider } from '../providers/notifications'
import { ConfirmDialogProvider } from '../providers/confirm-dialog'
import { keycloak as keycloakClient } from './keycloak'
import { UserInfo } from '../components/user-info'
import { AppEventsConsumer } from '../events/consumer'
import { isDebug, requireNotNull } from '../utils'
import { useKeycloakLogout } from '../shared/hooks/useKeycloakLogout'

import 'jsoneditor-react/es/editor.min.css'

const PagesList = React.lazy(async () => import('../pages/pages'))
const DetailPage = React.lazy(async () => import('../pages/detail-page'))
const ComponentsList = React.lazy(async () => import('../pages/components'))
const CreatePage = React.lazy(async () => import('../pages/createPage'))
const EditComponentPage = React.lazy(async () => import('../pages/editComponent'))
const NotFound = React.lazy(async () => import('../pages/not-found'))
const CreateComponent = React.lazy(async () => import('../pages/createComponent'))
const DocumentsPage = React.lazy(async () => import('src/pages/DocumentsPage'))
const PromoPagesPage = React.lazy(async () => import('../pages/promoPages'))
const VariablesPage = React.lazy(async () => import('../pages/variables'))

function Fallback() {
	const text = 'loading...'
	return <PageLayout title={text}>{text}</PageLayout>
}

const isValidAccessScope = (resourceAddresses?: Keycloak.KeycloakResourceAccess) => {
	if (!resourceAddresses) {
		return false
	}
	const scope = requireNotNull(window.__env__.KEYCLOAK_CLIENT_ID)
	return resourceAddresses[scope] && resourceAddresses[scope].roles.includes('access')
}

const isIgnoreKeycloak = window.__env__.IGNORE_KEYCLOAK === 'true'

function AppContent() {
	return (
		<QueryProvider>
			<ThemeProvider>
				<ConfirmDialogProvider>
					<NotificationsProvider>
						<AppEventsConsumer />
						{!isIgnoreKeycloak && <UserInfo />}
						<Suspense fallback={<Fallback />}>
							<Switch>
								<Route exact path={routes.home} component={PagesList} />
								<Route exact path={routes.components} component={ComponentsList} />
								<Route exact path={routes.documents} component={DocumentsPage} />
								<Route exact path={routes.createPage} component={CreatePage} />
								<Route exact path={routes.detailPageById} component={DetailPage} />
								<Route exact path={routes.editComponent} component={EditComponentPage} />
								<Route exact path={routes.createComponent} component={CreateComponent} />
								<Route exact path={routes.promoPages} component={PromoPagesPage} />
								<Route exact path={routes.variables} component={VariablesPage} />
								<Route path="*" component={NotFound} />
							</Switch>
						</Suspense>
					</NotificationsProvider>
				</ConfirmDialogProvider>
			</ThemeProvider>
		</QueryProvider>
	)
}

function AppKeycloakResolver() {
	const { keycloak } = useKeycloak()
	const { logout } = useKeycloakLogout()
	if (!keycloak.authenticated) {
		return <div>access denied</div>
	}

	if (!isValidAccessScope(keycloak.tokenParsed?.resource_access)) {
		return (
			<div>
				access denied <br />
				<div
					style={{ textDecoration: 'underline', cursor: 'pointer' }}
					onClick={logout}
					tabIndex={0}
					onKeyPress={logout}
				>
					try to another account
				</div>
			</div>
		)
	}

	return <AppContent />
}

const eventLogger = (event: unknown, error: unknown) => {
	if (!isDebug) {
		return
	}
	console.log('onKeycloakEvent', event, error)
}

export function App() {
	if (isIgnoreKeycloak) {
		return <AppContent />
	}

	return (
		<ReactKeycloakProvider
			onEvent={eventLogger}
			authClient={keycloakClient}
			LoadingComponent={<div>loading...</div>}
			initOptions={{
				onLoad: 'login-required',
				pkceMethod: 'S256',
				// eslint-disable-next-line no-restricted-globals
				redirectUri: location.href,
				checkLoginIframe: false,
			}}
		>
			<AppKeycloakResolver />
		</ReactKeycloakProvider>
	)
}
